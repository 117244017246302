<template>
  <el-menu :mode="mode" class="shopping-cart-wrapper">
    <el-menu-item :disabled="qty > 0 ? false : true" index="shopping-cart">
      <template slot="title">
        <nuxt-link
          :to="qty > 0 ? '/shopping-cart' : '/'"
          class="shopping-cart-cta"
        >
          <div class="right-shopcart fa-lg">
            <i class="right fas fa-shopping-cart" />
            <span class="right-cart-number" :class="[`products-${qty}`]">{{
              qty
            }}</span>
          </div>
        </nuxt-link>
      </template>
    </el-menu-item>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LadNavigationMenuShoppingCart',
  props: {
    mode: { type: String, required: true },
  },
  computed: {
    ...mapGetters('shopping-cart', ['qty']),
  },
}
</script>

<style lang="scss">
.shopping-cart-wrapper {
  &.el-menu--horizontal {
    .shopping-cart-cta {
      min-width: 60px;
    }
  }

  .right-shopcart {
    margin-left: 20px;
    position: relative;
    display: inline-block;

    @media (min-width: 992px) {
      margin-left: 0;
    }

    .right-cart-number {
      background: #199b39;
      max-width: 25px;
      min-width: 20px;
      height: 20px;
      color: #fff;
      border-radius: 10px;
      position: absolute;
      top: -15px;
      left: 15px;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      padding: 0 2px;

      &.products-0 {
        display: none;
      }
    }
  }
}
</style>
